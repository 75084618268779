import React from 'react';
import { useState } from 'react';
import './Main.css';
import axios from './AxiosInstance';
import { validEmail, validPassword, validUsername } from './Regex.js';
import { useNavigate } from "react-router-dom";

const Register = () => {

	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [usernameErr, setUsernameErr] = useState(true); //~for validation
	const [emailErr, setEmailErr] = useState(true); //~for validation
   	const [pwdErr, setPwdErr] = useState(true); //~validation


	const navigate = useNavigate();


	const handleClickUpdateBackend = (e) => { 
		
		e.preventDefault();
		console.log(usernameErr);

		//=if validation fails -> prevent axios running

		if (usernameErr||pwdErr||emailErr) {
			alert("double check your inputs")
			return
		} 



		//run the axios requests here sending username,email,pw: 
		axios.post('/register', 
		{
			username: username,
			email: email,
			password: password,
		}).then(response => {
			if(response.data.success){
				console.log(email);
				console.log(username);
				alert(response.data.message + ", you will now be directed to login page");
				navigate("/login");
			} else {
				alert(response.data.message)
			}

		}).catch(error => {
			console.error(error);
		});
	}


	const handleLoginUsername = (e) => {
		setUsername(e.target.value); 
		if (!validUsername.test(username)) { //test compares your value vs. the regular expression
			setUsernameErr(true);
		
		 } else {
			setUsernameErr(false);
		 }
	}

	const handleLoginEmail = (e) => {
		setEmail(e.target.value);
		if (!validEmail.test(email)){
			setEmailErr(true);
		} else {
			setEmailErr(false);
		}
	}

	const handleLoginPassword = (e) => {
		setPassword(e.target.value);
		if (!validPassword.test(password)) {
			setPwdErr(true);

		 } else {
			setPwdErr(false);
		 }
		
	} 

	return (
		<div className='loginContainer'>
			<div className='registerContainer'>
				<form onSubmit={(e) => handleClickUpdateBackend(e)}>
					Create account <br/><br/>

					<label>
						Username:<br/>
						<input type='text' value={username} placeholder="username" onChange={handleLoginUsername} /> 
					</label> <br/>
					{usernameErr? <span className="error">must be between 7-16 letters/numbers</span>: <span></span>}
						<br/>

					<label>
						Email:<br/>
						<input type='text' value={email} placeholder="email" onChange={handleLoginEmail} /> 
					</label>
						<br/>
						
					{emailErr? <span className="error">must be a valid email, including @</span>: <span></span>}
						<br/>


					<label>
						Password:<br/>
						<input type='password' value={password} placeholder="password " onChange={handleLoginPassword} /> 
					</label>
					<br/>
					{pwdErr? <span className="error">must be between 6-10 letters/numbers</span>: <span></span>}
					{/* <br/>{console.log("pwdErr: " +pwdErr)}  */}
					<br/><br/>

		{/*input fields CAN'T be blank- for some reason user deletes typed letters, the register button works again -
		 the last 3 prevents that from happening*/}
					{usernameErr || emailErr || pwdErr || username === "" || password === "" || email === "" ? 
						(<button type="button" disabled>Register new account</button>) : 
						(<button className="submitbtn">Register new account</button>)
					}

				
				</form>
			</div>
		</div>

	)
}

	export default Register

	//fix register button 

