import React from 'react'
import axios from './AxiosInstance';
import { useEffect, useState } from 'react';
import ItemTile from './Components/ItemTile';
import { Link } from 'react-router-dom';
import './Main.css';

//3rd PARENT OF ITEMTILE 
const MyItems = () => {
	const storedUserId = localStorage.getItem("storedUserId");

	useEffect(() => {
		axios.post('/getItemsInfo', {
			params: [storedUserId],
			whereStatement: "WHERE users.userId = ?"
		
		}).then(response => {
		setItemsData(response.data);
		console.log(response.data)
		})
	},[])



	const [itemsData, setItemsData] = useState([]);

	if(itemsData.length>0){
		return (
			<div style={{fontSize:"25px", margin:"15px 10px"}}>
				Your items for sale:
				<hr className="dashed"></hr>
				<div style={{display:"flex", flexWrap:"wrap"}}>
						{itemsData.map((product) => ( 
							<ItemTile data={product} myItems />
						))}
				</div>
				<Link to="/user/myitems/additems"> 
					<button className="modifyItemButton">Add an item for sale</button>
				</Link>

			</div>
		)
	} else {
		<div>		
			<button className="submitbtn">Add an item for sale</button> <br/>
		</div>

	}

  
}

export default MyItems
