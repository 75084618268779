import React, { useEffect } from "react";
import Picbanner from "./Components/Picbanner";
import Onsale from "./Components/Onsale";
import './Main.css';

const Home = (props) => {
	localStorage.getItem("storedFullname");
	const cartQ = Number(localStorage.getItem("cartQuantity"));
	console.log(cartQ);


	//~for testing: use w button
	// const testupdateCartQ = () =>{ 
	// 		props.updateCartQ(Number(localStorage.getItem("cartQuantity")));
	// }

	//lerp : 2 things & a percentage start/end pos



	return (<>
		<div>
			<Picbanner />
		</div>

		<div>

			<Onsale
				updateCartQ={props.updateCartQ}
				showAdd1toCart
			/>
		</div>
	</>
	)
};

export default Home;