import React from 'react';
import './Main.css';
import AddModifyForm from './Components/AddModifyForm';


const ModifyItem = () => {
	

	return (<>
			<AddModifyForm modify/> 	
	</>)
}

export default ModifyItem


//const rerender = (e) => {
	// 	const changeProperty = "productName";
	// 	creating a new object using old itemData object w/ one new variable that user inputs 
	// 	const newObject = {...itemData, [changeProperty]: e.target.value};
	// 	setItemData(newObject); 
	// }
