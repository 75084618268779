import React from 'react'
import App from '../App';
import { BrowserRouter } from "react-router-dom";
import Navbar from './Navbar';
import { useState } from 'react';


const RootComponent = () => {
//only using localstorage number so when user refreshes, they see initial value which wil be updated thruout any changes
	const [cartQ, setCartQ] = useState(Number(localStorage.getItem("cartQuantity")));
	const [navbar, setNavbarRerender] = useState("");
	console.log("localstorage cartq:" + localStorage.getItem("cartQuantity") +"cartQ: " + cartQ);

	const updateCartQ = (newQ, addorMinusMore ) =>{
		if (addorMinusMore > 0) { 
			setCartQ(cartQ + addorMinusMore)
			//disregards newQ; not useful; could be any number when child calls this function
		} else {
			setCartQ(newQ);
		}
		console.log("what is newQuantity being passed in here..: " + newQ)
	}
	
	const rerenderNavbarName = (status) => { 
		setNavbarRerender(status);
	}
	console.log("what is navname passed as here:" + navbar)



	return (
		// <BrowserRouter basename="/react">
		<BrowserRouter>
			<Navbar cartQ = {cartQ}/>
			<App updateCartQ= {updateCartQ} rerenderNavbarName ={rerenderNavbarName} /> 
		</BrowserRouter>
	)
}


export default RootComponent
