import React from 'react'
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../AxiosInstance';
import '../Main.css';
import DiscreteSliderStepsComp from './DiscreteSliderStepsComp';

const AddModifyForm = (props) => {

//all the SAME useStates - modify -> exactly same ones as ADD 
	const navigate = useNavigate();
	const productId = useParams().productId; 
	const [itemData, setItemData] = useState(null);//so that my if works later
	const [productName, setProductName] = useState("");
	const [imagesrc, setImagesrc] = useState("");
	const [unitprice, setUnitPrice] = useState("");
	const [sale, setSale] = useState("");
	const [aftersale, setAftersale] = useState();
	const [desc, setDesc] = useState("");
	const [status, setStatusMsg]= useState("");
	const [userId, setUserId] = useState("");
	const storedUserId = localStorage.getItem("storedUserId");
//child uses the setter function to set VALUE FROM SLIDER   
	const [valueFromSlider, setvalueFromSlider] = useState(0);

//callback function to be passed to Slider:

function sendValue (value) { 
	setvalueFromSlider(value);
}

//~ 4 modifying - to fetch existing data: 

	useEffect(() => {

		axios.post('/getItemsInfo', 
		{
			params: [productId],//needs to be an array for backend
			whereStatement: "WHERE productId = ?"
		}).then(response => {
			if (response.data.length === 0) {
				return;
			}
			//would error without this if..->non existing item w nonexisting name
			setItemData(response.data[0])
			setProductName(response.data[0].name)
			setImagesrc(response.data[0].imagesrc)
			setUnitPrice(response.data[0].unitprice)
			setSale(response.data[0].sale*100)
			setDesc(response.data[0].description)
			setUserId(response.data[0].userId)
			console.log("it shows up then yes to productId")
		})
	},[productId])
//only for modify, or else when 'add' item !itemData = true which will show 'no such item'
if (props.modify) {

	if (!itemData) {
		return (<div>No such item!</div>)
	}
	
	if (storedUserId != userId){
		return (<div>This is not your item!</div>)
	} 
}

//!GIANT HANDLECLICK:

const handleClickUpdateBackend = (e) => { 
	e.preventDefault();
	//~ run this if ADDING product: 
		if (props.add) {
			axios.post('/addnew', 
			{
				productName: productName,
				userId: storedUserId,
				imagesrc: imagesrc, 
				unitprice: unitprice,
				sale: valueFromSlider/100, 
				desc: desc,
			}).then((response) => { 
				setStatusMsg("added to db");
				console.log(response.data);
				//"insertId is the automatic ID assigned by db "
				console.log(response.data.insertId);
				navigate(`/items/${response.data.insertId}`)
			}).catch(error => {
				console.error(error);
			})
		} else if (props.modify) {

			axios.post('/modifyold',
			{
				productName: productName,
				imagesrc: imagesrc, 
				unitprice:unitprice, 
				sale: valueFromSlider/100, 
				desc: desc,
				productId: productId,
				userId: userId,
				storedUserId: storedUserId,
			}).then((responseModify)=> { 
				setStatusMsg("Item info updated!");
				navigate(`/items/${productId}`)
				//need to clear interval or page will keep navigating to product
			}).catch(error => {
				console.error(error);
			})
		}
}

//!HANDLECLICK ends

const changeProductName = (e) => {
	setProductName(e.target.value);
}


const changeImage = (e) => {
	setImagesrc(e.target.value);
}


const changePrice = (e) => {
	setUnitPrice(e.target.value);
}


const changeDesc = (e) => {
	setDesc(e.target.value);
}

//calculating aftersale price based on value given by child slider: 
const salePrice = (unitprice*((100-valueFromSlider)/100)).toFixed(2); 

	return (<>
		<div className="addModifyForm">
		{
			<form onSubmit={(e) => handleClickUpdateBackend(e)}>
				{props.add? 
					(<><div className = "subtitle">Add a new item:</div><br/></>) : 
					(<><div className = "subtitle">Modify this item: </div><br/></>)
				}

				{/*div for form starts*/}
				<ul className="formContainer">
					<li>
						<label>Name</label>
						<input type='text' name="productname" value={productName} onChange={changeProductName}/> 					
					</li>

					
					<li>
						<label> </label>
							<img style={{width: "200px", height:"200px"}} src={imagesrc} alt='image source link'/> 
					</li>

					<li>
						<label>Image link</label>
						<input type='text' placeholder="image link" value={imagesrc} onChange={changeImage} /> 						
					</li>

					<li>
						<label>Price </label>
						
						<input type='number' min="0.1" max="10000.00" step="0.01"  placeholder="unit price" value={unitprice} onChange={changePrice} /> 	
					</li>
			
					<li>
						<label>Sale percentage</label>
						{/* child comp of addmodifyform */}
						<DiscreteSliderStepsComp sendValue={sendValue} />
					</li>

					<li className="afterSale">
						After sale price:${salePrice}

						
					</li>

					<li>
					<label> Description </label>
						<textarea className="input" type='text' placeholder="description of product" value={desc} onChange={changeDesc} /> 
					</li>
				</ul>

					{productName==="" || imagesrc==="" || unitprice==="" || desc==="" ? 
						(<button className="disabledBtn" type="button" disabled>Confirm submission</button>) 
						: 
						(<button className="submitbtn">Confirm submission</button>)
					}

					{status}
			
			</form>
		
		}
		</div>
	</>)
}

export default AddModifyForm
