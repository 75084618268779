
import React from 'react'
import { useState, useEffect } from 'react';
import axios from './AxiosInstance';
import ItemTile from './Components/ItemTile';
//~PARENT FUNCTION OF ALL ARRAY INFO (QUANTITY, USERID, USERNAME, ETC)
const Items = (props) => {

	const [itemsData, setItemsData] = useState([]);	

	const storedFullname = localStorage.getItem("storedFullname");
	const storedUserId = localStorage.getItem("storedUserId");



	useEffect(()=>{
		axios.post('/getItemsInfo', 
		{
			params: "",
			whereStatement: ""
		}).then(response => {
			setItemsData(response.data);
		})
	}, [])



	console.log("this is itemsData the array")
	console.log(itemsData);

	//= stars function does not need to be here

	return (
		<>
ZXCVXCJB XCKVXB JXCKVB JXCVB JK
				<div style={{display: "flex", flexWrap:"wrap"}}>

					{itemsData.map(product => (
						<ItemTile 
							key={product.productId} //for React: to avoid rendering items that have not changed
							data={product} 
							showAdd1toCart
							updateCartQ = {props.updateCartQ}
							
						/>
					))}

					{/*
					passing PRODUCT onto itemtile, which contains all info from array (quantity, userId, etc)
					map works here as if there was an if statement saying IF array is empty return nothing.directly put var from useState will not werk. */}
				</div>
			
		</>
	)	

}

export default Items



