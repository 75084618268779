import React from 'react'
import { useState, useEffect } from 'react'
import axios from '../AxiosInstance'
import ItemTile from './ItemTile'
import '../Main.css';

const Onsale = (props) => {
	useEffect(() => {
		axios.post('/getItemsInfo',
			{
				params: "", //needs to be an array for backend
				whereStatement: "WHERE sale > 0"
			}
		).then(response => {
			setItemsData(response.data);
			console.log("here are the onsale items response.data:")
			console.log(response.data)
		})
	}, [])

	const [itemsData, setItemsData] = useState([]);
	return (
		<div style={{ fontSize: "34px", fontFamily: "monospace", position: "absolute", marginTop: "530px" }}>
			<img style={{ width: "100px", padding: "20px 20px 0 20px" }} src="https://media.karousell.com/media/photos/products/2021/5/27/were_on_sale_1622085570_f367ccfc_progressive.jpg" alt='on sale' />
			Today's deals:
			<hr className="dashed" style={{ marginLeft: "20px" }}></hr>


			<div className="saleContainer">
				{itemsData.map(x => (
					<ItemTile
						key={x.productId}
						data={x}
						page="Home"
						updateCartQ={props.updateCartQ}
						showAdd1toCart
					/>
				))}
			</div>

		</div>
	)
}

export default Onsale
