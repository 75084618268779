//regular expressions

export const validEmail = new RegExp(
	'^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
);

export const validPassword = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,10}$');

export const validUsername = new RegExp('^[0-9A-Za-z]{6,10}$');


