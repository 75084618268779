import React, { useEffect } from 'react'
import { useState } from 'react';
import { useParams} from 'react-router-dom'
import axios from './AxiosInstance';
import ItemTile from './Components/ItemTile';

const User = () => {
	const userId = useParams().userId; 

	useEffect(() => {
		axios.post('/getItemsInfo', {
			params: [userId],
			whereStatement: "WHERE users.userId = ?"
		
		}).then(response => {
		setItemsData(response.data);
		console.log(response.data)
		})
	},[])

	const [itemsData, setItemsData] = useState([]);


	if(isNaN(userId) || itemsData.length<=0 ) {
		return(
			<div>
				No user is found. Like wai you do dis.

			</div>
		)
	}



  return (
	//itemsData contains the array of products vendor sells. map over it using itemtile, which displays each product. 
	//product contains ONE ARRAY ONLY 
	<div style={{	display: "flex", flexWrap: "wrap"}}>
		{itemsData.map((product => ( 
			<ItemTile data={product} /> 
			)))
		}
		
	</div>

  )
}



export default User
