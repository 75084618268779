import React from 'react';
import './Main.css';
import AddModifyForm from './Components/AddModifyForm';
//parent of AddModifyForm
const AddItem = () => {
//encapsulation-files only contain necessary codes

	return (<>
			<AddModifyForm add/> 	
	</>)
}
export default AddItem


	// only using one useState for everything:  const [productdata, setProductdata]= useState({ });
	// const addSushi = (e) => {
	// 	let tempdata = productdata;
	// 	tempdata.sushi = e.target.value;
	// 	setProductdata(tempdata);
	// }