import React from 'react'
import { Link } from 'react-router-dom'
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as fatStar} from '@fortawesome/free-regular-svg-icons'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../Main.css';
import axios from '../AxiosInstance';
import DropdownQuantity from './DropdownQuantity';
//ItemTile is both the child of ITEMS, and CART, and MYITEMS
const ItemTile = (props) => {


	const storedFullname = localStorage.getItem("storedFullname");
	const storedUserId = parseInt(localStorage.getItem("storedUserId"));

	const Stars = (x) => {
		let stars = [];
		for (let i=0; i<x; i++) { 
			stars.push(<FontAwesomeIcon icon={faStar} key={i}/>)
		};

		for(let i=x; i<5; i++) {
			stars.push(<FontAwesomeIcon icon={fatStar}/>)
		};
		return (<div style={{color:"gold"}}>{stars}</div>)
	}


//=little cart adding 1 to cart ONLY on front page, and item page

	const addtoDb = () => 
	{
		axios.post('/addtoDb', 
		{
			productId: props.data.productId, //passed from Items
			storedUserId: storedUserId,
			quantity: 1
		}).then(response =>{
			if (storedUserId) {
				alert(response.data.message);		
				props.updateCartQ(0, 1);
				
			} else {
				alert(response.data.message) //displays not logged in message
			}
			
		})
	};

//~for splitting prices into whole & dec, dec being supercript 
	const onSalePrice = (props.data.unitprice*(1-props.data.sale)).toFixed(2).toString();
	const onSalePArray = onSalePrice.split(".");
	// console.log(onSalePrice, onSalePArray[0], onSalePArray[1]);
	const originalPrice = (props.data.unitprice).toFixed(2).toString();
	const originalPriceArray = originalPrice.split(".");

//=wide cart tile=// 

	if (props.wideCartTile) {
		
		return (<>
				<div className="wideTileContainer" >
					<div className="wideTileleftPicContainer">
						<Link style={{textDecoration:"none"}} to={`/items/${props.data.productId}`}>
							{<img className="itemImg" src={props.data.imagesrc} alt='image source link'/>}<br/>
						</Link>
					</div>
							
						
			{/*name and price*/}
					<div className="wideTileRightContainer">
						{props.data.name} <br/>
						<div style={{fontSize:"20px", margin:"10px"}}>
							Vendor: {props.data.username} 
						</div>
						<DropdownQuantity 
							rerenderCart={props.rerenderCart} 
							updateCartQ={props.updateCartQ} 
							productId={props.data.productId} 
							name={props.data.name} 
							quantity={props.data.quantity} />
					</div>

					<div className="wideTilePriceContainer" > 
							{/*ON SALE VS NOT ONSALE*/}
							{props.data.sale!==0 ?
							(<>
								<div>
									<span style={{fontSize:"18px"}}>ON SALE NOW: </span>
									<span className="dollarSignOrDecimal">$</span>
									<span>{onSalePArray[0]}</span>
									<span className="dollarSignOrDecimal">{onSalePArray[1]}</span>
								</div>
								<div>
									<span className="originalPrice"> Original price: </span>
									<span className="dollarSignOrDecimal originalPrice">$</span>
									<span className="originalPrice">{originalPriceArray[0]}</span>
									<span className="dollarSignOrDecimal originalPrice">{originalPriceArray[1]}</span>
								</div>
								
							</>) : 

							(<>
								<span className='dollarSignOrDecimal'>$</span>
								<span>{originalPriceArray[0]}</span>
								<span className="dollarSignOrDecimal">{originalPriceArray[1]}</span>
							</>)	
						}
					</div>
				</div>
			</>
		)}

//=not on cart page 
	else {
		return ( 
			<>
				<div className="indContainer" >
						
					<Link className="itemlink" to={`/items/${props.data.productId}`}>
						{<img className="itemImg" src={props.data.imagesrc} alt='image source link'/>}
						{/*name and price*/}
						<div style={{fontSize:"23px", textAlign:"left", marginTop:"10px"}}>
							{props.data.name} <br/>
							{
									props.data.sale!==0 ?
									(<>
										<div>
											<span style={{fontSize:"18px"}}>ON SALE NOW: </span>
											<span className="dollarSignOrDecimal">$</span>
											<span>{onSalePArray[0]}</span>
											<span className="dollarSignOrDecimal">{onSalePArray[1]}</span>
										</div>
										<div>
											<span className="originalPrice"> Original price: </span>
											<span className="dollarSignOrDecimal originalPrice">$</span>
											<span className="originalPrice">{originalPriceArray[0]}</span>
											<span className="dollarSignOrDecimal originalPrice">{originalPriceArray[1]}</span>
										</div>
										
									</>) : 

									(<>
										<span className='dollarSignOrDecimal'>$</span>
										<span>{originalPriceArray[0]}</span>
										<span className="dollarSignOrDecimal">{originalPriceArray[1]}</span>
									</>)	
								}
						</div>
					</Link>
		
	
					<div style={{fontSize:"18px", textAlign:"left"}}> 

					{!props.myItems &&
						(<div>Vendor: {props.data.username} {storedUserId == props.data.userId && "(You)"} <br/></div>)
					}

						<div>
							{Stars(props.data.rating)}
						</div>
						
						{props.showAdd1toCart && (storedUserId != props.data.userId) &&
							(<img onClick={addtoDb} className="add1toCartButton" src="https://i.pinimg.com/564x/c8/65/d7/c865d7f0d75850d51a79cd891b908974.jpg" alt='add'/>)
						}
		
						{storedUserId == props.data.userId && 
							(<div><Link to ={`/user/myitems/modify/${props.data.productId}`}><button className="modifyItemButton">Modify Item</button></Link> </div>)
						}	
					</div>
				</div>		
			</>)
	}
	

}


export default ItemTile
