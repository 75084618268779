import React from 'react';
import { Link } from 'react-router-dom';
import '../Main.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';

const Navbar = (props) => {


		const storedUserId =localStorage.getItem("storedUserId");
		//console.log(storedUserId);
		const storedFullname = localStorage.getItem("storedFullname");
		//console.log(storedFullname);
		
	
//if user is logged in display welcome + username; if not just 'Login'. 
	const loginButton = () =>{

		if (storedFullname) { 
			return (`Welcome! ${storedFullname}`)
		} else {
			return ("Login")
		}
	}

	function link(link, label) { 
		return (
			<Link style={{cursor:"grab"}}to={link} className={"linkCss"}>{label}</Link>
		)
	}


	return (<>

    <div className="navbar">
		<div> 
			&nbsp;{link("/", "Home")}

		</div>
		
		<div>
		
			{link("/login", loginButton() )}
			{link("/items", "Items")}
			<span style={{margin:"none"}}>
				<div className="cartQNav">{props.cartQ}</div>
				<span className="cartIcon">
					{link("/cart", (<FontAwesomeIcon icon={faCartShopping} style={{color: "#B197FC"}} />))}
				</span>
			</span>
			

		</div>

    </div>
  </>);
}

export default Navbar;
