import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import Cart from "./Cart";
import Items from "./Items";
import EachItem from "./EachItem";
import User from "./User";
import MyItems from "./MyItems";
import Register from "./Register";
import AddItem from "./AddItem";
import ModifyItem from "./ModifyItem";



const ShopRoutes = (props) => {
	return (
		<Routes>
			<Route path="/" element={<Home updateCartQ={props.updateCartQ} />} />
			<Route path="cart" element={<Cart updateCartQ={props.updateCartQ} rerenderNavbarName={props.rerenderNavbarName} />} />
			<Route path="login" element={<Login rerenderNavbarName={props.rerenderNavbarName} updateCartQ={props.updateCartQ} />} />
			<Route path="register" element={<Register />} />
			<Route path="items" element={<Items updateCartQ={props.updateCartQ} />} />
			<Route path="items/:productId" element={<EachItem updateCartQ={props.updateCartQ} />} />
			<Route path="user/:userId" element={<User />} />
			<Route path="user/myitems" element={<MyItems />} />
			<Route path="user/myitems/additems" element={<AddItem />} />
			<Route path="user/myitems/modify/:productId" element={<ModifyItem />} />
		</Routes>
	)
}


function App(props) {
	//child of Root, parent of ShopRoutes
	return (

		
		<Routes>
			<Route path="/" element={<> YOUR PORTFOLIO PAGE </>} />
			<Route path="shop/*" element={<ShopRoutes updateCartQ = {props.updateCartQ} rerenderNavbarName = {props.rerenderNavbarName} />} />
		</Routes>
	);
}

export default App;
