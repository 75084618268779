import React from 'react';
import { useState } from 'react';
import axios from './AxiosInstance';
import './Main.css';
import { Link } from 'react-router-dom';

//call rerenderindex here to rerender whole page
const Login = (props) => {
	
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [loginUserId, setLoginUserId] = useState(""); //from session! backend. first axios req. 
	const [loginUsername, setLoginUsername] = useState("");//from session too
	const [loginStat, setLoginStat] = useState();
//using local storage to store key value 

	// axios.defaults.withCredentials = true; //= FOR VALIDATING USER must include or would not work
	
//!DOES NOT NEED TO BE IN USEEFFECT: due to it's clicked by BUTTON. get SESSION userId. 

//getting user from localstorage

const storedFullname = localStorage.getItem("storedFullname");
const storedUserId = localStorage.getItem("storedUserId");

	
		const handleClickUpdateBackend = (e) => { 
			e.preventDefault();
			
			//run the axios requests here: 
			axios.post('/login', 
			{
				username: username,
				password: password,
			}).then(response => {
				if (response.data.success) {
					
					setLoginStat("Welcome");
					localStorage.setItem("storedFullname", response.data.fullname);
					localStorage.setItem("storedUserId", response.data.userId);
					//~ props.rerenderNavbarName("rerender after logging in!") 
					//-- above code:  DO  NOT NEED: BECAUSE OF UPDATECARTQ later which is ran to get quantity in the next axios.post:
					
						//~get quantity for navbar cart q: 
						axios.post("/gettotalquantity", 
							{
								userId: response.data.userId,
							}).then(qResponse => {
								let totalQuantityinCart = 0;
								for (let i=0; i<qResponse.data.length; i++) {
									console.log(qResponse.data[0]);
									totalQuantityinCart = qResponse.data[i].quantity+totalQuantityinCart;
								}
								props.updateCartQ(totalQuantityinCart); //~yay rerenders root -> whole page
								localStorage.setItem("cartQuantity", totalQuantityinCart);
								console.log("totalQinCart:" + totalQuantityinCart)			
							})
				}

				else if(!response.data.success) { 
					setLoginStat("Wrong login information.")
				}

			}).catch(error => {
				console.error(error);
			});
		}

	
	
	const handleLoginUsername = (e) => {
		setUsername(e.target.value);
	}

	const handleLoginPassword = (e) => {
		setPassword(e.target.value);
	}

	const logout = () => { 

		axios.delete("/logout").then(()=>{
			localStorage.clear();
			setLoginStat();
			setLoginUserId();
			setLoginUsername(); //~added those two now navbar works fine when loggedout
			props.rerenderNavbarName("rerender!");
			props.updateCartQ(0); //have to manually set it, clearing localstorage only for refreshing purposes
		})
	}

//=LOGIN !

// console.log("this is session's userId " + loginUserId)
// console.log("Login Stat and username:")
// console.log(loginStat)
// console.log(username)

	if (!storedFullname) { 
			
		return (
			<>
				<div className='loginContainer'>
					<div className='smallContainer'>
						<form onSubmit={(e) => handleClickUpdateBackend(e)}>
							Please enter username and password: <br/><br/>

							Username: <br/> <input type='text' value={username} onChange={handleLoginUsername} /> <br />
							Password: <br/> <input type='password' value={password} onChange={handleLoginPassword} /><br />
							<br/>
							<button className="submitbtn"> Log in</button>
							<br/><br/>
						
							{loginStat}
							
						</form>
					</div>
				</div>

					<div className="smallContainerNoBorder" > 
						New to our shop? Create a new account: 
						<Link to="/register">
							<button className="submitbtn">Create A New Account</button>
						</Link>
					</div>

			</>
		)

		} else {
			return (
				<>
				<div style={{fontSize:"35px", padding:"20px"}}> 
					Welcome, {storedFullname}

					<hr className="dashed"></hr>


					<div className="buttonsContainer">
						<Link to = "/cart"><button className="buttonz"><img style={{width:"80px"}}  src="https://cdn-icons-png.flaticon.com/512/1179/1179868.png" alt="Cart Icon"/> <br/>My Cart</button></Link>
						<Link to = "/user/myitems"><button className="buttonz"><img style={{width:"80px"}} src = "https://static.thenounproject.com/png/3069450-200.png" alt="My Items"/><br/>My Items</button></Link>
						<button className="buttonz" onClick={logout}> <img style={{width:"80px"}} src ="https://cdn-icons-png.flaticon.com/512/223/223301.png" alt="Log Out"/> <br/> Log Out</button>
					</div>
				</div>
				</>
			)
}}

export default Login

/* NEED onChanges for updating pw and username values; then form ON SUBMIT for updating the newly updated values into db. 
*/