import React from 'react';
import ReactDOM from 'react-dom/client';
import RootComponent from './Components/RootComponent';


const root = ReactDOM.createRoot(document.getElementById('root'));

	root.render(
		<RootComponent />
	);


