import React from 'react'
import { useState } from 'react';
import '../Main.css';

const Picbanner = () => {
	const [index, setIndex] = useState(0);
	const imgArr = [ 
		"https://offloadmedia.feverup.com/secretatlanta.co/wp-content/uploads/2022/05/13063559/55813383_839352243078125_43637-1024x683.jpg", 
		"https://media.timeout.com/images/105805341/750/562/image.jpg"
	];
  return (
	<div style={{position:"absolute"}}>
		<button className="btnLeft" onClick={()=>{
				index === 1? 
				setIndex(0): setIndex(1);
				}}>
		←
	 	 
		</button>
		<img style={{width:"99vw", height:"500px"}} src={imgArr[index]} />
		<button className="btnRight" onClick={()=>{
				index === 0?
				setIndex(1): setIndex(0);
				}}>
		→
		</button>

	  
	</div>
  )
}

export default Picbanner
