import React from 'react'
import { useEffect, useState } from 'react';
import axios from './AxiosInstance'
import './Main.css';
import ItemTile from './Components/ItemTile';
import { Link } from 'react-router-dom';

const Cart = (props) => {
	//using storedUserId from localstorage so when page is refreshed user stays logged in

	const storedUserId = localStorage.getItem("storedUserId");
	const [usersCartItems, setusersCartItems] = useState([]);	
	const [rerenderCarty, setRerenderCarty] = useState(0);

	//child (dropdownq) will call this function so cart page rerenders
	function rerenderCart(n) {
		setRerenderCarty(58+Math.random()); 
	}

	useEffect(()=> {
		if (storedUserId) {
			axios.post('/getCartItems', 
				{
					storedUserId: storedUserId
				}
			).then((response)=>{
				if (storedUserId) { 
					setusersCartItems(response.data); //re-renders AFTER EVERYTHING ELSE is ran
				} else {
					console.log(response.data.message)
				}
			})
		} 
	}, [rerenderCarty]);

	if (!storedUserId) {
		// console.log("cartlength:"+usersCartItems.length);
		return ("please log in to view cart");
	} 

	

	//~you're logged in, and your cart is not empty:	
	if (usersCartItems.length>0) {
		// console.log("cartlength:"+usersCartItems.length);
		
		let totalCost = 0;
		let totalCartQuantity = 0;
		let price = 0;
		//= ~~~~~~cart quantity: sets localstorage q based on server quantity & call setter fn: ~~~~~~~~//
		for (let i=0; i<usersCartItems.length; i++) {
			totalCartQuantity = totalCartQuantity + usersCartItems[i].quantity;
			console.log("usersCartItems.length: " + usersCartItems.length + ".totalQ:  "+totalCartQuantity);
			localStorage.setItem("cartQuantity", Number(totalCartQuantity));
			props.updateCartQ(totalCartQuantity);
			
			//gets price for any item that is NOT on sale
			if (usersCartItems[i].sale===0) { 
				price = usersCartItems[i].unitprice;
				// console.log("normal price: " + price)

			//calculate price for any item that is on sale
			} else { 
				// console.log(usersCartItems[i].sale)
				price = usersCartItems[i].unitprice*(1-usersCartItems[i].sale);
				// console.log("discount price:" + price)
			}

			//total cost: final price * quantity
			totalCost = (totalCost + (price * usersCartItems[i].quantity)).toFixed(2);
		}		



		return (<>

				<div style={{ margin: "10px 0 0 20px", fontSize: "28px" }}>
					Shopping Cart 
					<hr className="dashed"></hr>
				</div>

				<div className="cartcontainer">
				
					<div style={{ flex: "3", display: "flex", flexWrap: "wrap" }}>
						{usersCartItems.map(product => (
							<ItemTile 
								updateCartQ = {props.updateCartQ} 
								rerenderCart = {rerenderCart} 
								data={product} 
								page="Cart" 
								wideCartTile
							/>
						))}
					</div>
					
					<div style={{ flex: "2", textAlign:"right", fontSize: "28px", marginRight:"20px"}}>
						Subtotal ({totalCartQuantity} items): ${totalCost}
		
						{console.log(rerenderCarty)}
					</div>
				</div>
		</>); //~  ============== END of IF  cart is not empty ============== //

		// ============== BEGINNING of If Cart is empty ==================//
	} if (usersCartItems.length===0) {
		// console.log("cartlength:"+usersCartItems.length);
		props.updateCartQ(0);
		localStorage.setItem("cartQuantity", 0);
		return (<>
			<div className='cartemptycontainer'>
				You have no items in cart! 
				<br/><br/>
				<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8vxcXbyvy5JHHV_7wMO_HQv-j6aZxX0I5MA&s" />
				<br/><br/>

			</div>

			<div className="buttonsContainer"> 
				<Link to="/" ><button className="buttonz">Return to Home Page</button></Link>
			</div>
		</>);


	} else {
		return ("ERROR- what happened")
	}
}

export default Cart
