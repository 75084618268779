import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';


export default function DiscreteSliderStepsComp(props) {

	function valueLabelFormat(value){
		//calling useState in parent(addmodifyform)
		props.sendValue(value);
		return (value + "% off")
	}
	
  return (
    <Box sx={{ width: 300 }}>
      <Slider
        aria-label="Small steps"
        defaultValue={0}
        // getAriaValueText={valuetext} DON'T NEED: this is just decorative 
        step={1}
        min={0}
        max={99}
        valueLabelDisplay="auto"
		// custom label for the slider's value
		valueLabelFormat={valueLabelFormat}
      />
    </Box>
  );
}