import 'bootstrap/dist/css/bootstrap.css';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import axios from '../AxiosInstance';
import React from 'react'
import { useState } from 'react';
import '../Main.css';

//!CHILD component; passing productId from parent - ItemTile (which is a child of cart)
const DropdownQuantity = (props) => {

	const storedUserId = localStorage.getItem("storedUserId");
	
	const [quantity, setQuantity] = useState(props.quantity);//passed from CART->ItemTile->DropdownTile

//~ONCLICK: 
	const updateQuantity = (AmountShownButton) => { 
		setQuantity(AmountShownButton);
		console.log("Amount on button rn:" + AmountShownButton);

		if (AmountShownButton===0) { 
		//DELETING ITEM from db:
			axios.delete("/deleteitem", 
			{data: {productId: props.productId}}
			).then(()=>{
				alert("item deleted from cart");
				setQuantity(0);
				props.rerenderCart(8*Math.random());
				console.log("what is rerenderCart:" + props.rerenderCart);
			})
		} else {
		//MODIFY item quantity: 
				axios.post("/modifyCartDb", 
					{
						productId: props.productId, //=passed down from ItemTile 
						quantity: AmountShownButton,
						storedUserId: storedUserId,
					}).then((response)=>{
						console.log(response.data);	
						setQuantity(AmountShownButton);
						props.rerenderCart(Math.random());
						alert("you now have " + AmountShownButton + " " + props.name + " in your cart");
					});
				};
	}	
//~ONCLICK updateQuantity function ends


//~ARR for quantity #s on dropdown menu: 

	let maxQuantity = 10;
	if (quantity>5) { 
		maxQuantity = quantity+5;
	}

	const arr = [];

	for (let i=1; i<=maxQuantity; i++) {
		arr.push(<Dropdown.Item onClick={() => updateQuantity(i)} as="button" key={i}>{i}</Dropdown.Item>)

	}


	return (<>
		<div className="dropdown-and-cart-container">
			<DropdownButton id="dropdown-basic-button" title={`Amount: ${props.quantity}`}>
				<Dropdown.Item onClick={() => updateQuantity(0)} as="button">0(Delete)</Dropdown.Item>
				
				{arr}
			</DropdownButton>
		</div>
	</>)
}

export default DropdownQuantity
