import React from 'react'
import { useState } from 'react';
import '../Main.css';
import axios from '../AxiosInstance';

const ModifyQuantityEachItem = (props) => {
	const arrayQuantityNum = [1,2,3,4,5,6]; 
	const [index, setIndex] = useState(0); 

	const storedFullname = localStorage.getItem("storedFullname");
	const storedUserId = localStorage.getItem("storedUserId");



	const handleClickAdd = ()=> { 
		if (index<5) {
			setIndex(index+1);
		} //largest index#? 5.
	}

	const handleClickMinus = ()=> { 
		if (index>=0) {
			setIndex(index-1);
		} 
	}

	console.log("index: " + index);

	const handleClickAddtoCart = (n) => { 
		axios.post("/addtoDb", 
			{
				productId: props.productId, 
				quantity: n,
				storedUserId: storedUserId,
			}).then((response)=>{
				if (response.data.success) {
					// console.log(response.data);
					alert( n + " added to your cart");
					console.log("n:" + n + "response.data.quantity:" + response.data.quantity)
					//updating little navbar cart q:
					props.updateCartQ(0, n);
					
					
				} else {
					alert(response.data.message)
				}
		});
	};


  return (<>
	<div className="containerQuantity">
		<button onClick={handleClickMinus}> - </button>
		Quantity: {arrayQuantityNum[index]}
		<button onClick={handleClickAdd}> + </button>
	</div>
	<div className="containerQuantity">
		<button onClick={()=>{handleClickAddtoCart(index+1)}}> Add to cart </button>
	</div>
	</>)
}

export default ModifyQuantityEachItem
