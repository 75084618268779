import React, { useEffect } from 'react'
import { useState } from 'react';
import { useParams, Link } from 'react-router-dom'
import axios from './AxiosInstance';
import './Main.css';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as fatStar} from '@fortawesome/free-regular-svg-icons'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModifyQuantityEachItem from './Components/ModifyQuantityEachItem';


const EachItem = (props) => {
	const productId = useParams().productId; //USEPARAMS RETURN AN OBJ
	const storedUserId = Number(localStorage.getItem("storedUserId"));

	
	useEffect(() => {
		axios.post('/getItemsInfo', 
		{
			params: [productId],//needs to be an array for backend
			whereStatement: "WHERE productId = ?"
		}).then(response => {
			setItemsData(response.data)
		})
	},[])


	const [itemsData, setItemsData] = useState([]);

	console.log(itemsData)



	if(isNaN(productId) || itemsData.length<=0 ) {
		return(
			<div>
				No item is found. Like wai you do dis.

			</div>
		)
	}

	const Stars = (x) => {
		let stars = [];
		for (let i=0; i<x; i++) { 
			stars.push(<FontAwesomeIcon icon={faStar} key={i}/>)
		};

		for(let i=x; i<5; i++) {
			stars.push(<FontAwesomeIcon icon={fatStar}/>)
		};
		return (<div style={{color:"gold"}}>{stars}</div>)
	}
//for splitting prices: same as itemtile's. 
	const originalPrice = (itemsData[0].unitprice).toFixed(2).toString();
	const originalPriceArray = originalPrice.split(".");
	const onSalePrice = (itemsData[0].unitprice*(1-itemsData[0].sale)).toFixed(2).toString();
	const onSalePArray = onSalePrice.split(".");


	
	
return (
	<div className="eachItemContainer">
		<div className="leftContainer"> 
			{<img className="eachItemImg" src = {itemsData[0].imagesrc} />}
		</div>
		<div className="rightContainer">
			{itemsData[0].name} 
				{//=IF ON SALE=//
					itemsData[0].sale>0? 
					(<>
						<div className="eachitemSaleContainer">
							<span>ON SALE NOW: </span>
							<span className="dollarSignOrDecimal">$</span>
							<span>{onSalePArray[0]}</span>
							<span className="dollarSignOrDecimal">{onSalePArray[1]}</span>
						</div>
						<div className="eachitemSaleContainer">
							<span className="originalPrice"> Original price: </span>
							<span className="dollarSignOrDecimal originalPrice">$</span>
							<span className="originalPrice">{originalPriceArray[0]}</span>
							<span className="dollarSignOrDecimal originalPrice">{originalPriceArray[1]}</span>
						</div>
										
					</>) : 

					(<>
						<div>
							<span className='dollarSignOrDecimal'>$</span>
							<span>{originalPriceArray[0]}</span>
							<span className="dollarSignOrDecimal">{originalPriceArray[1]}</span>
						</div>
					</>)	
				//=end=//
				}

				<div style={{fontSize:"18px", marginTop:"5px"}}>
					{Stars(itemsData[0].rating)} 
					<br/>
					<Link to={`/user/${itemsData[0].userId}`} style={{textDecoration:"none"}}>Vendor: {itemsData[0].username} {itemsData[0].userId == storedUserId && "(You)"}</Link>
				</div>
				
				<div style={{fontSize:"30px", marginTop:"22px"}}> 
					{itemsData[0].description}
					
				</div>

				{/*Option to add quantities only show when item doesn't belong to vendor */}
				{itemsData[0].userId !== storedUserId && (
					<ModifyQuantityEachItem productId={productId} updateCartQ = {props.updateCartQ} />)
				}

				{/*Option to modify only shows when item belongz to vendor */}
				{itemsData[0].userId === storedUserId && 
					(<div><Link to ={`/user/myitems/modify/${itemsData[0].productId}`}><button className="modifyItemButton">Modify Item</button></Link> </div>)
				}

		</div>
	</div>

  )
}

export default EachItem
